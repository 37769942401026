@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-item-shop {
  display: flex;
  flex-direction: column;
  overflow: visible; // при нажатии по кнопке чтобы была выидна обводка
  gap: 6px;
  @include fixedSize(100%, max-content); //305

  @include min-428-break {
    gap: 8px;
    @include fixedSize(100%, max-content); //345
  }

  @include min-744-break {
    @include fixedSize(100%, max-content); //305
  }
}

.item-rarity-card,
.item-rarity-shop-card {
  position: absolute;
}

.item-rarity-card {
  @include singleFixedSize(100px);
}

.properties-slot {
  display: flex;
  width: 100%;
  z-index: 10;
  flex-wrap: wrap;
  min-height: max-content;
  max-height: max-content;
  @include min-428-break {
    min-height: max-content;
    max-height: max-content;
  }
}

.bottom-slot {
  margin-top: 0;

  @include min-428-break {
    margin-top: 4px;
  }

  @include min-744-break {
    margin-top: 0;
  }
}
